<template>
    <div class="grid">
		<div class="col-12 lg:col-6 xl:col-6">
			<div class="card mb-0 text-center pt-6 pb-6 countCard">
				<div class="flex justify-content-center ">
					<div>
						<span class="block text-500 font-medium mb-3">
                            当前持有宝石数量
                        </span>
						<div class="text-color-green font-medium text-xl">
							{{GemListNow.length}}
						</div>
					</div>
					
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
			</div>
		</div>
        <div class="col-12 lg:col-6 xl:col-6">
			<div class="card mb-0 text-center pt-6 pb-6 countCard">
				<div class="flex justify-content-center ">
					<div>
						<span class="block text-500 font-medium mb-3">
                            持有宝石总伤害
                        </span>
						<div class="text-green-500 font-medium text-xl">
							{{ArmMaxCount}}
						</div>
					</div>
					
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
			</div>
		</div>
     
    </div>
	<div class="content_title mt-4 mb-4">
        创建宝石
    </div>
	<div class="grid">
		<div class="col-12 lg:col-6 xl:col-4">
			<createGem @getGemListEvent=getGemListEvent />
			<p class="mt-2">当前抽取宝石的价格为：<span class="text-warning text-bold">{{getGemsPrice}} {{tokenName}}</span></p>
		</div>
	</div>
    <div class="content_title mt-4 mb-4">
        我的宝石
    </div>
	<div class="grid mb-4">
		<div class="col-12">
			 <Button label="刷新我的宝石列表" icon="iconfont icon-jiaose-" 
    			class="mr-2" @click="getGemListEvent()"></Button>
		</div>
		<div class="col-12 mt-2">
			品质筛选：
		</div>
		<div class="col-12">
			<Button label="All" class="mr-2" @click="filterGem(0)" :class="filterNum == 0 ?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="1" class="mr-2" @click="filterGem(1)" :class="filterNum==1?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="2" class="mr-2" @click="filterGem(2)" :class="filterNum==2?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="3" class="mr-2" @click="filterGem(3)" :class="filterNum==3?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="4" class="mr-2" @click="filterGem(4)" :class="filterNum==4?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="5" class="mr-2" @click="filterGem(5)" :class="filterNum==5?'active':'p-button-raised p-button-secondary'"></Button>
		</div>
	</div>
		
	<div class="grid ">
			<div class="col-12 lg:col-6 xl:col-3"  v-for="item in GemListNow" v-if="GemListNow">
				<div class="card mb-0 text-center imgCard text-left gemCard">

					<img :src='require("@/assets/img/gem/"+item.quality.toNumber()+".jpg")' />
					<div class="content ">
						<div class="flex justify-content-between">
							<h5 class="title">
								{{item.name}}
							</h5>
							<p>品质：{{item.quality.toNumber()}}</p>
						</div>
						<p class="flex justify-content-between">
							<span>攻击力加成：</span>
							<span class="text-danger">{{item.aggressivity.toNumber()}}</span>
						</p>
						
					</div>
					<div class="imgBoxShow">
						<div style="width:100%">
							<div class="iconBox mb-4">
								<destroyGemModal :info="item.itemId.toNumber()" @getGemListEvent="getGemListEvent"/>
		
								<tarnsferGem :roleInfo="item" @tarnferGemEvent="tarnferGemEvent" />
								<sellGemModal :roleInfo="item" @sellGemEvent="sellGemEvent"/>
							</div>
							
						</div>
					</div>
				</div>
			</div>
			
		</div>
</template>


<script>
import { defineComponent,ref,store,computed} from 'vue';

import createGem from "../components/Create/gem.vue";
import {getGemsList,getGemsLuckDrawPrice} from "../wallet/LogicGem";
import sellGemModal from "../components/Modal/sellGemModal.vue";
import tarnsferGem from "../components/Modal/tarnsferGem.vue";
import destroyGemModal from "../components/Modal/destroyGem.vue";
import {sortArr} from "../hooks/getAttr";
export default defineComponent({
    name:"gemPage",
	
 	components: {
		createGem,
		sellGemModal,
		tarnsferGem,
		destroyGemModal
	},
	setup(){
		let GemList=ref('');
		let GemListNow=ref('');
		let filterNum=ref(0);
		let getGemsPrice=ref();
		let GemMaxRank=ref();
		let ArmMaxCount=ref(0);
		const getGemListEvent=()=>{
			GemList.value='';
			getGemsList().then(res=>{
				console.log("获取地址下的所有宝石",res)
				GemList.value=res;
				GemListNow.value=res;
				sortArr(res,1).then(res=>{
					GemListNow.value = res;
				});
				let arrs = res.map((value, index, array) => {
					return value.aggressivity
				})
				GemMaxRank.value=Math.max(...arrs);
				ArmMaxCount.value=arrs.reduce(( acc, cur ) => {
					return Number(acc) + Number(cur)
				}, 0);
			}).catch(error=>{
				console.log("调用方法失败")
			})
		}
		getGemListEvent();
		//上架武器组件调用
		const sellGemEvent = () => {
			
			getGemListEvent();
		}

		//赠送宝石组件调用
		const tarnferGemEvent = () => {
			getGemListEvent();
		}

		//筛选宝石
		const filterGem=(quality)=>{
			filterNum.value=quality;
			if(quality!=0){
				let filterGemArr=GemList.value.filter((value,key,arr) => {
					return value.quality.toNumber() == quality? true :  false; 
				})
				GemListNow.value=filterGemArr;
			}else{
				GemListNow.value=GemList.value;
			}
			
		}
		//抽取宝石价格
		getGemsLuckDrawPrice().then(res=>{
			getGemsPrice.value=res;
		})
		return{
			GemList,
			GemListNow,
			filterGem,
			filterNum,
			getGemListEvent,
			tarnferGemEvent,
			sellGemEvent,
			getGemsPrice,
			GemMaxRank,
			ArmMaxCount
		}
	}
	
})
</script>

<style lang="scss" scoped>
.imgCard{
	position: relative;
	img{
	
		height: -webkit-fill-available;
		
	}
	.imgBoxShow:deep{
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
		top: 0;
		display: flex;
		align-items: center;

		padding: 0 20px;
		display: none;

		.iconBox {
			height: auto;
			display: flex;
			justify-content: space-between;

			.iconfont {
				display: block;
				background: $themeColor;
				color: #16202F;
				padding: 10px;
				border-radius: $radisIconbtn;

				// margin: 0 8px;
				&:last-child {
					margin: 0;
				}

				&:first-child {
					margin: 0;
				}
			}
		}

		.functionBtn {}
	}
	&:hover {
		.imgBoxShow {
			display: flex;
		}
	}
}
</style>