<template>
	
    <i class="iconfont icon-shanchu" @click="ModalOpen"/>
    <Dialog header="确认删除" v-model:visible="display" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true" @update=update>
       <p class="text-center">
           销毁后将返回 {{MathPrice(Number(ethers.utils.formatEther($store.getters.getDestroyPrice)))}} {{tokenName}}，是否确认删除该宝石？
       </p>
      <hr>
      <div class="text-center">
        <Button label="立即删除" @click="comfirm" icon="pi pi-check" />
         
      </div>
    </Dialog>

   
</template>

<script>
import { useStore } from "vuex";
import { defineComponent,ref,watch } from 'vue';
import Dialog from 'primevue/dialog'; 
import InputText from 'primevue/inputtext';
import {destroyGemstone} from "../../wallet/LogicGem";
export default defineComponent({
    name:"destroyGemModal",
    emits: ["getGemListEvent"],
    props: [
        'info'
    ],
    setup(props,{emit}) {
        const store = useStore();
        const info=ref(props.info)
        let display=ref(props.showNameModal);
        let address=ref("");
      
      
        watch(()=>display.value,()=>{
            if(!display.value){
                emit('close',display.value);
            }
           
        })
        watch(()=>props.info,()=>{
            info.value=props.info
           
        })
        const ModalOpen=()=>{
            
             display.value=true
        }
        const comfirm=()=>{
            
            display.value=false;
          	destroyGemstone(info.value).then(res=>{
				emit("getGemListEvent")
			})
            return 
        }

      
       

        return {
            ModalOpen,
            address,
            comfirm,
            display,
            
        }
        
    },
    components : {
        Dialog,
        
        InputText
    }
    
})
</script>
