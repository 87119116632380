<template>
	  <i class="iconfont icon-liwu"  @click.stop="ModalOpen()" />
    <Dialog header="" v-model:visible="display" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true" @update=update>
        <h5 class="mb-4 text-center">宝石赠送给他人</h5>
        <div class="mt-2">
              <span class="p-float-label ">
                <InputText id="username" type="text" v-model="address"
                 style="width:100%" />
                <label for="username">请输入对方地址</label>
            </span>
        </div>
      
        <template #footer>
            <Button label="确定" @click="comfirm" icon="pi pi-check" 
            class="p-button-secondary"/>
        </template>
    </Dialog>

   
</template>

<script>
import { useStore } from "vuex";
import { defineComponent,ref,watch } from 'vue';
import Dialog from 'primevue/dialog'; 
import InputText from 'primevue/inputtext';
import * as LogicTransferFrom from "../../wallet/LogicTransferFrom";
import {gemTransfer_setApprovalForAll,gemTransfer_isApprovedForAll} from "../../wallet/nftAuto";
export default defineComponent({
    name:"tarnsferGemModal",
    emits: ["tarnferGemEvent"],
    props: [
        'roleInfo'
    ],
    setup(props,{emit}) {
         const store = useStore();
         const roleInfo=ref(props.roleInfo)
        let display=ref(props.showNameModal);
        let address=ref("");
       
       let isGemDayModalShow=ref(false);
       let GemDayAmount=ref(7);
        //console.log("tests");
        watch(
            () => props.showNameModal,
            () => {
                display.value=props.showNameModal;
            }
        );
        watch(()=>display.value,()=>{
            if(!display.value){
                emit('close',display.value);
            }
           
        })
        watch(()=>props.roleInfo,()=>{
            roleInfo.value=props.roleInfo
           
        })
        const ModalOpen=()=>{
             console.log("传过来的角色信息",roleInfo.value)
             display.value=true
        }
        const comfirm=()=>{
               display.value=false;
           LogicTransferFrom.gemsTransferFrom(address.value,(roleInfo.value.itemId).toNumber()).then(res=>{
             emit('tarnferGemEvent') 
           })
         
            return 
        }

         //检查授权情况   
        const isTransferTokenAuth=()=>{
             LogicTransferFrom.isAllowance().then(res=>{
                 console.log("转账合约调用检查授权",res);
                 if(res>0){
                      store.commit("UpdateIsTransferTokenAuth",true)
                     return true
                 }else{
                     store.commit("UpdateIsTransferTokenAuth",false)
                     return false;
                 }
             })
        }
        //isTransferTokenAuth()

        //token 授权
        const toApprove=()=>{
            LogicTransferFrom.toApprove().then(res=>{
                if(res){
                     
                    store.commit("UpdateIsTransferTokenAuth",true)
                    return true
                }else{
                    store.commit("UpdateIsTransferTokenAuth",false)
                    return false;
                }
            })
        }

     
        //检查NFT授权
        // gemTransfer_isApprovedForAll().then(res=>{
        //     console.log("市场合约返回授权全部调用结果",res)
        //     if(res){
        //        store.commit("UpdateIsGemTransferNftAuth",true)
        //     }else{
        //         store.commit("UpdateIsGemTransferNftAuth",false)
        //     }
        // })

        // NFT 授权
         const authTipsNft=ref("当前暂未授权市场Nft合约,点击授权");
        const isAuthNftNow=ref(false);
        const toNftTransfer=()=>{
            isAuthNftNow.value=true;
            authTipsNft.value="正在唤起钱包授权";
            gemTransfer_setApprovalForAll().then(res=>{
                console.log("武器组件调用授权",res);
                isAuthNftNow.value=false;
                if(res){
                   
                    store.commit("UpdateIsGemTransferNftAuth",true)
                    return true
                }else{
                  
                    store.commit("UpdateIsGemTransferNftAuth",false)
                    return false;
                }
            })
        }
       

        return {
            ModalOpen,
            address,
            GemDayAmount,
            comfirm,
            display,
            toNftTransfer,
            toApprove
        }
        
    },
    components : {
        Dialog,
        
        InputText
    }
    
})
</script>
