<template>
<i class="iconfont icon-gouwucheman" @click.stop="sellGem()"/>

    
    <Dialog header="" v-model:visible="display" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true" @update=update>
        <h5 class="mb-4 text-center">是否确认上架该宝石</h5>
        <div class="mt-2 text-center">
              
              <h5 class="mb-4 text-center">{{roleInfo.name}}</h5>
                <div class="mt-2">
                    <span class="p-float-label ">
                        <InputText id="username" type="Number" 
                        v-model="psAmount" style="width:100%" />
                        <label for="username">售价</label>
                    </span>
                </div>
        </div>
      
        <template #footer>
            <Button label="确定" @click="comfirm" icon="pi pi-check" 
            class="p-button-secondary"/>
        </template>
    </Dialog>

    
</template>

<script>
import { useStore } from "vuex";
import { defineComponent,ref,watch } from 'vue';
import Dialog from 'primevue/dialog'; 
import InputText from 'primevue/inputtext';
import * as LogicMarket from "../../wallet/LogicMarket";
import {
   
    marketGem_isApprovedForAll,
    marketGem_setApprovalForAll,
   
} from "../../wallet/nftAuto";
export default defineComponent({
    name:"sellArmModal",
    emits: ["createRoleEvent","sellGemEvent"],
    props: [
        'roleInfo'
    ],
    setup(props,{emit}) {
        const store = useStore();
         const roleInfo=ref(props.roleInfo)
        let display=ref(props.showNameModal);
        let psAmount=ref("");
       
       let isGemDayModalShow=ref(false);
       let GemDayAmount=ref(7);
        //console.log("tests");
        watch(
            () => props.showNameModal,
            () => {
                display.value=props.showNameModal;
            }
        );
        watch(()=>display.value,()=>{
            if(!display.value){
                emit('close',display.value);
            }
           
        })
         watch(()=>props.roleInfo,()=>{
            roleInfo.value=props.roleInfo
           
        })
        const sellGem=()=>{
             console.log("传过来的宝石信息",roleInfo.value.itemId.toNumber())
             display.value=true
        }
        const comfirm=()=>{
               display.value=false;
                LogicMarket.sellGemstone((roleInfo.value.itemId).toNumber(),psAmount.value).then(res=>{
                   emit("sellGemEvent");
                })
         
            return 
        }

         //检查授权情况   
        const isMarketTokenAuth=()=>{
             LogicMarket.isAllowance().then(res=>{
                 console.log("武器组件调用检查授权",res);
                 if(res>0){
                      store.commit("UpdateIsMarketTokenAuth",true)
                     return true
                 }else{
                     store.commit("UpdateIsMarketTokenAuth",false)
                     return false;
                 }
             })
        }
       // isMarketTokenAuth()
        //授权武器合约
        const authTips=ref("当前暂未授权市场Token合约,点击授权");
        const isAuthNow=ref(false);
        const approveMarket=()=>{
            isAuthNow.value=true;
            authTips.value="正在唤起钱包授权";
            LogicMarket.toApprove().then(res=>{
                console.log("武器组件调用授权",res);
                isAuthNow.value=false;
                if(res){
                      store.commit("UpdateIsMarketTokenAuth",true)
                   // store.commit("UpdateIsMarketNftAuth",true)
                    return true
                }else{
                     store.commit("UpdateIsMarketTokenAuth",false)
                   // store.commit("UpdateIsMarketNftAuth",false)
                    return false;
                }
            })
        }

        //检查NFT授权
        // marketGem_isApprovedForAll().then(res=>{
        //     console.log("市场合约返回授权全部调用结果",res)
        //     if(res){
        //        store.commit("UpdateIsMarketGemNftAuth",true)
        //     }else{
        //         store.commit("UpdateIsMarketGemNftAuth",false)
        //     }
        // })

        //market NFT 授权
         const authTipsNft=ref("当前暂未授权市场武器Nft合约,点击授权");
        const isAuthNftNow=ref(false);
        const toNftMarket=()=>{
            isAuthNftNow.value=true;
            authTipsNft.value="正在唤起钱包授权";
            marketGem_setApprovalForAll().then(res=>{
                console.log("武器组件调用授权",res);
                isAuthNftNow.value=false;
                if(res){
                   
                    store.commit("UpdateIsMarketGemNftAuth",true)
                    return true
                }else{
                  
                    store.commit("UpdateIsMarketGemNftAuth",false)
                    return false;
                }
            })
        }

        return {
            
            sellGem,
            roleInfo,
            psAmount,
            comfirm,
            display,
            approveMarket,
            authTips,
            toNftMarket,
            isAuthNftNow,
            authTipsNft
        }
        
    },
    components : {
        Dialog,
        
        InputText
    }
    
})
</script>
