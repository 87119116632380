<template>
  
    <Button label="抽取宝石" icon="iconfont icon-jiaose-" 
    class="mr-2" @click="createGemEvent()" v-if="$store.getters.getXyjTokenBalance>0"></Button>
    <Button label="当前余额不足，请先充值" icon="iconfont icon-jiaose-" 
    class="mr-2" disabled v-else/>
</template>


<script>
import { defineComponent,ref,store,computed} from 'vue';
import { useStore } from "vuex";
import {getBlbalance} from "../../wallet/tokenAuto";
import * as GemApi from "../../wallet/LogicGem";

export default defineComponent({
    name:"createGem",
    emits: ["getGemListEvent"],
    setup(props, {
      emit
    }){
        const store = useStore();
        //创建角色
        const createGemEvent=()=>{
            
             GemApi.extractGemsApi().then(res=>{
                 console.log("创建宝石成功2")
                   store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)-1);
                 getBlbalance();
                 emit('getGemListEvent')
             }).catch(error=>{
                   store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)-1);
                 console.log("创建宝石失败")
             })
        }

        //检查授权情况   
        const isGemTokenAuth=()=>{
             GemApi.isAllowance().then(res=>{
                 console.log("宝石组件调用检查授权",res);
                 if(res>0){
                      store.commit("UpdateIsGemTokenAuth",true)
                     return true
                 }else{
                     store.commit("UpdateIsGemTokenAuth",false)
                     return false;
                 }
             })
        }
        //isGemTokenAuth()
        //授权武器合约
        const authTips=ref("当前暂未授权宝石合约,点击授权");
        const isAuthNow=ref(false);
        const approveGems=()=>{
            isAuthNow.value=true;
            authTips.value="正在唤起钱包授权";
            GemApi.toApprove().then(res=>{
                
                isAuthNow.value=false;
                if(res){
                     
                    store.commit("UpdateIsGemTokenAuth",true)
                    return true
                }else{
                    authTips.value="当前暂未授权宝石合约,点击授权3";
                    store.commit("UpdateIsGemTokenAuth",false)
                    return false;
                }
            }).catch(error=>{
                authTips.value="当前暂未授权宝石合约,点击授权1";
                 store.commit("UpdateIsGemTokenAuth",false)
                    return false;
            })
        }

        return{
            approveGems,
            isGemTokenAuth,
            authTips,
          createGemEvent
        }
    },
    
    
})
</script>
